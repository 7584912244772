<template>
  <div class="content__body">
    <template v-if="team_info">
      <h2 class="content__title title">Информация о команде</h2>
      <div class="team-info">
        <div class="team-info__row">
          <div class="team-info__key">Название команды:</div>
          <div class="team-info__value">{{ team_info.team.name }}</div>
        </div>
        <div class="team-info__row">
          <div class="team-info__key">Дата создания:</div>
          <div class="team-info__value">{{ team_info.team.created_at }}</div>
        </div>
        <div v-if="team_info.team.org_text" class="team-info__row">
          <div class="team-info__key">Организация:</div>
          <div class="team-info__value">{{ team_info.team.org_text }}</div>
        </div>
        <div class="team-info__row">
          <div class="team-info__key">Количество участников:</div>
          <div class="team-info__value">{{ team_info.members.length || 0 }}</div>
        </div>
        <div v-if="team_info.team.region_text" class="team-info__row">
          <div class="team-info__key">Регион:</div>
          <div class="team-info__value">{{ team_info.team.region_text }}</div>
        </div>
        <div v-if="team_info.team.org_text" class="team-info__row">
          <div class="team-info__key">Образовательная организация:</div>
          <div class="team-info__value">{{ team_info.team.org_text }}</div>
        </div>
        <div v-if="team_info.team.desc" class="team-info__row team-info__row--column">
          <div class="team-info__key">Описание:</div>
          <div class="team-info__value">{{ team_info.team.desc }}</div>
        </div>
      </div>
    </template>
    <h2 class="content__title title">Личные данные</h2>
    <app-accordion v-for="(user_info, index) in members_info" :key="index" :title="user_info.main_info.full_name">
      <div class="personal-info">
        <div class="personal-info__block">
          <div class="personal-info__photo">
            <img v-if="user_info.main_info.crop_url" :src="user_info.main_info.crop_url" alt="User photo">
            <img v-else src="@/assets/img/user-empty-photo.svg" alt="Slug">
          </div>
          <div class="personal-info__about">
            <div class="personal-info__name">{{ user_info.main_info.full_name }}</div>
            <div class="personal-info__line">
              <b>Дата рождения:</b>
              <span>{{ user_info.main_info.dob }}</span>
            </div>
          </div>
        </div>
        <div class="personal-info__cols">
          <div v-for="line of user_info.main_info.other_labels" :key="line.field" class="personal-info__line">
            <b>{{ line.label }}:</b>
            <span>{{ line.value }}</span>
          </div>
        </div>
      </div>
      <div class="socials-block">
        <template v-for="labels in user_info.other_labels">
          <div v-for="label in labels" :key="label.field" class="socials-block__line">
            <img :src="require(`@/assets/img/accordion/${label.field}.svg`)" alt="Icon">
            <a :href="label.value" target="_blank">{{ label.value }}</a>
          </div>
        </template>
      </div>
      <div v-for="(accordion, index) in user_info.accordions" :key="index" class="accordion-view">
        <div class="accordion-view__subt">{{ accordion.label }}</div>
        <template v-for="(block, i) in accordion.data">
          <div v-if="Array.isArray(block)" :key="i" class="accordion-view__block">
            <template v-for="(part, key) in block">
              <div v-if="part.value" :key="key" class="accordion-view__line">
                <b>{{ part.label }}:</b> <span>{{ part.value }}</span>
              </div>
            </template>
          </div>
          <template v-else>
            <div v-if="block.value" :key="i" class="accordion-view__line">
              <b>{{ block.label }}:</b> <span>{{ block.value }}</span>
            </div>
          </template>
        </template>
      </div>
    </app-accordion>

    <h2 class="title title--indent">Результаты анкетирования</h2>
    <div class="questionary">
      <div v-for="result in form_result.filter(item => item.value && item.type !== 'bParagraph')" :key="result.id" class="questionary__item">
        <div class="questionary__question">{{ result.label }}:</div>
        <p v-if="typeof result.value == 'string'" class="questionary__answer">{{ result.value }}</p>
        <p v-if="Array.isArray(result.value)" class="questionary__answer">
          <span v-for="(value, i) in result.value" :key="i">
            {{ value }}{{ i !== result.value.length - 1 ? ',' : '' }}
          </span>
        </p>
        <p v-if="typeof result.value == 'object' && !Array.isArray(result.value)" class="questionary__answer">
          <template v-if="result.value.hasOwnProperty('file')">
            <a :href="result.value.file" target="_blank" class="link">посмотреть</a>
          </template>
          <template v-else>
            <template v-if="result.type === 'bOrg'">
              {{ result.value.full_name }}
            </template>
            {{ result.value.address }}
          </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationDetail',
  props: ['app'],
  data() {
    return {
      members_info: {
        main_info: {},
        accordions: [],
        other_labels: [],
      },
      form_result: [],
      team_info: null
    }
  },
  created() {
    this.members_info = this.$props.app.members_info
    this.form_result = this.$props.app.form_result
    this.team_info = this.$props.app.team_info
  },
}
</script>
